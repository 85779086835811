// 
// authentication.scss
//


// authentication home icon
.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

.bg-welcome{
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
}

.card-welcome{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.cgu_content{
  height: 32vh;
  padding: 10px;
  overflow-y: scroll;
  border: 1px solid #eee;
}

.pointer{
  cursor: pointer;
}